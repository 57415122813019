.showPropertyTable {
    position: relative;
    width: calc(100% - 30px);
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.10);

    padding: 20px 15px;
    margin: 5vh 0;

    .upperBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .upperHeading {
            width: max-content;
            color: #000;
            font-family: 'DM Sans', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
        }

        .searchBox {
            width: 220px;
            color: #000;
            font-family: 'DM Sans', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;

            border-radius: 10px;
            border: 2px solid rgba(0, 0, 0, 0.20);
            background: #FFF;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
            padding: 10px 10px;

            input {
                border-radius: 10px;
                width: 100%;
                border: none;
                outline: none;
            }

            img {
                width: 20px;
            }
        }
    }

    .dataTableStyles {
        width: 100%;
        margin-top: 5vh;

        .headingOfTable {
            width: 100%;
            border-radius: 10px;
            background: #EDF1F7;

            display: grid;
            grid-template-columns: 2fr 2fr 2fr 3fr;
            gap: 40px;

            padding-top: 20px;
            padding-bottom: 20px;

            .headingItem {
                color: #000;
                font-family: 'DM Sans', sans-serif;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;

                &:nth-child(1) {
                    text-align: center;
                }
            }
        }

        .entryOfTable {
            width: 100%;

            display: grid;
            grid-template-columns: 2fr 2fr 2fr 3fr;
            gap: 40px;
            align-items: center;

            padding-top: 10px;
            padding-bottom: 10px;

            .imageEntry {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 10px;
                align-items: center;
                margin-top: 3vh;

                span {
                    color: #000;
                    font-family: 'DM Sans', sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    text-transform: capitalize;
                }

                img {
                    width: 100%;
                    height: 125px;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }

            .nameEntry {
                color: #000;
                font-family: 'DM Sans', sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                text-transform: capitalize;
            }

            .locationEntry {
                color: #000;
                font-family: 'DM Sans', sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                text-transform: capitalize;
            }

            .modifyingOption {
                width: 100%;

                .allButtons {
                    width: 100%;

                    display: flex;
                    justify-content: space-between;
                    gap: 20px;

                    .button {
                        width: max-content;
                        display: flex;
                        gap: 10px;
                        padding: 10px 15px;

                        color: #FFF;
                        font-family: 'DM Sans', sans-serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;

                        border-radius: 10px;

                        cursor: pointer;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .viewButton {
                        background: #2E8AFF;
                    }

                    .editButton {
                        background: #FFC42E;
                    }

                    .deleteButton {
                        background: #A92525;
                    }
                }
            }
        }
    }
}

.notificationFormProperty {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: #00000090;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 100;

    animation: slowCome 0.7s ease;

    .mainBoxNotificationBox {
        width: calc(30% - 30px);
        height: max-content;

        padding: 20px 15px;

        border-radius: 4px;
        border: 2px solid #FFF;
        background: #FFF;

        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.10);

        display: grid;
        grid-template-columns: 25px 1fr;
        gap: 20px;

        .iconOfNotificationBox {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .textBoxOfNotificationBox {
            width: 100%;

            .headingOfNotification {
                color: #424242;
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
            }

            .subTextOfNotification {
                color: #757575;

                font-family: 'DM Sans', sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                /* 150% */
            }

            .actionButtons {
                display: flex;
                justify-content: flex-end;
                padding: 10px 0 0;
                gap: 10px;

                .cancelButton {
                    width: max-content;
                    padding: 10px 20px;
                    background: #D3DFCE;
                    color: #06750A;

                    font-family: 'DM Sans', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                }

                .confirmButton {
                    width: max-content;
                    padding: 10px 20px;
                    background: #14C21B;
                    color: #FFF;
                    
                    font-family: 'DM Sans', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }
        }
    }
    
    .confirmationBox {
        border: 2px solid #B6CAAE;
        background: #B6CAAE;
    }
}

@keyframes slowCome {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}