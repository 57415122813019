
// For Notification Box
.notificationForm {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: #00000090;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 100;

    animation: slowCome 0.7s ease;

    .mainBoxNotificationBox {
        width: calc(30% - 30px);
        height: max-content;

        padding: 20px 15px;

        border-radius: 4px;
        border: 2px solid #FFF;
        background: #FFF;

        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.10);

        display: grid;
        grid-template-columns: 25px 1fr;
        gap: 20px;

        .iconOfNotificationBox {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .textBoxOfNotificationBox {
            width: 100%;

            .headingOfNotification {
                color: #424242;
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
            }

            .subTextOfNotification {
                color: #757575;

                font-family: 'DM Sans', sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                /* 150% */
            }

            .actionButtons {
                display: flex;
                justify-content: flex-end;
                padding: 10px 0 0;
                gap: 10px;

                .cancelButton {
                    width: max-content;
                    padding: 10px 20px;
                    background: #D3DFCE;
                    color: #06750A;

                    font-family: 'DM Sans', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                }

                .confirmButton {
                    width: max-content;
                    padding: 10px 20px;
                    background: #14C21B;
                    color: #FFF;
                    
                    font-family: 'DM Sans', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }
        }
    }
    
    .confirmationBox {
        border: 2px solid #B6CAAE;
        background: #B6CAAE;
    }
}

@keyframes slowCome {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}