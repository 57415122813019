.workingBody {
    width: 100vw;
    height: 100vh;

    overflow: hidden;
    z-index: 999;

    .navbarOfAdmin {
        position: relative;
        width: 100%;
        height: 80px;

        background: #FFFFFF;

        .innerSectionOfNavbar {
            padding: 25px 2.5%;
            height: calc(100% - 50px);
            display: flex;
            justify-content: space-between;

            .logoBox {
                height: 100%;

                img {
                    height: 100%;
                }
            }

            .rightBox {
                display: flex;
                gap: 5vw;

                .profileIcon {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }

                .dropDown {
                    position: absolute;
                    height: max-content;
                    top: 100%;
                    right: 2.5%;
                    background: #FFF;
                    padding: 20px;
                    border-radius: 0 0 10px 10px;
                    
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    z-index: 1200;

                    .optionPanel {
                        display: flex;
                        align-items: center;
                        gap: 30px;
                        height: max-content;
                        cursor: pointer;
    
                        img {
                            height: 30px;
                        }
    
                        span {
                            font-family: 'DM Sans', sans-serif;
                            font-size: 14px;
                            font-weight: 800;
                            line-height: 14px;
                            letter-spacing: 0em;
                            text-align: center;
    
                        }
                    }
                }

            }
        }
    }

    .mainBody {
        width: 100%;
        height: calc(100vh - 80px);

        background: #EDF1F7;

        display: grid;
        grid-template-columns: 15% auto;
        gap: 5%;

        .leftPanel {
            width: 100%;
            height: 100%;

            overflow: auto;

            background: #FFFFFF;

            .mainMenuBox {
                width: 100%;
                padding: 20px 0px;

                .eachMenuSection {
                    width: 100%;

                    .sectionHeading {
                        width: calc(100% - 20px);
                        margin: 0 10px;
                        font-family: 'DM Sans', sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0em;
                        text-align: left;

                        text-transform: uppercase;

                        border-bottom: 2px solid #DADADA;

                        margin-bottom: 10px;
                    }

                    .menus {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        cursor: pointer;

                        padding: 30px 0;
                        margin: 5px 0;

                        background: transparent;
                        transition: all 1s ease;

                        .menuIcon {
                            width: 35px;
                            height: 35px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .menuName {
                            width: 50%;
                            text-wrap: wrap;

                            font-family: 'DM Sans', sans-serif;
                            font-size: 17px;
                            font-weight: 700;
                            line-height: 24px;
                            letter-spacing: 0em;
                            text-align: center;

                            color: #6F6F6F;

                            transition: all 1s ease;

                        }
                    }

                    .active {
                        // #EDF1F7, #FFFFFF00
                        background: rgb(237, 241, 247);
                        background: -moz-linear-gradient(90deg, rgba(237, 241, 247, 1) 0%, rgba(0, 0, 0, 0) 100%);
                        background: -webkit-linear-gradient(90deg, rgba(237, 241, 247, 1) 0%, rgba(0, 0, 0, 0) 100%);
                        background: linear-gradient(90deg, rgba(237, 241, 247, 1) 0%, rgba(0, 0, 0, 0) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#edf1f7", endColorstr="#000000", GradientType=1);

                        .menuIcon {
                            background-color: #4318FF;

                            img {
                                background: white;
                                mix-blend-mode: hard-light;
                            }
                        }

                        .menuName {
                            color: #4318FF;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: -5px;
                            width: 10px;
                            height: 70%;

                            transform: translateY(-50%);

                            background: #4318FF;
                            border-radius: 10px;

                            animation: menuActiveLineComeUp 1s;
                            transition: all 0.5s ease-in-out;
                        }
                    }
                }
            }

            /* For Scroll Bar */
            /* width */
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .centerPanel {
            width: 95%;
            height: 100%;
            padding-right: 5%;

            overflow: auto;
        }
    }
}

@keyframes menuActiveLineComeUp {
    0% {
        left: -10px;
    }

    100% {
        left: -5px;
    }
}


// For Notification
.notificationBar {
    width: 100vw;
    height: 100vh;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #0000006b;

    animation: 1s wakeUpNotification ease;

    .smallWhiteBox {
        width: 300px;
        min-height: 150px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;

        background: #FFFFFF;

    }
}

@keyframes wakeUpNotification {
    0% {
        background: #00000000;
    }

    100% {
        background: #0000006b;
    }
}