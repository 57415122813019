.welcomeAdmin {
    width: 100%;
    padding: 5vh 0;

    .innerWelcomeAdmin {
        width: 100%;

        display: flex;
        justify-content: space-between;

        .leftSidePanel {
            width: max-content;

            display: flex;
            gap: 20px;

            .avatarBox {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                background: #FFFFFF;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .infoBox {
                height: 100%;

                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 5px;

                .nameAvatar {
                    color: #43444E;
                    font-family: 'DM Sans', sans-serif;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                    text-transform: capitalize;
                }

                .niceMassage {
                    color: rgba(122, 122, 122, 0.74);
                    text-align: center;
                    font-family: 'DM Sans', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    text-transform: capitalize;
                }
            }
        }

        .rightSidePanel {
            width: max-content;

            .exportBox {
                display: flex;
                gap: 10px;
                justify-content: center;
                align-items: center;

                padding: 10px;
                cursor: pointer;

                border-radius: 5px;
                background: #FFF;
                box-shadow: 0px 7.20539px 14.41077px 0px rgba(0, 0, 0, 0.15);

                .massageExportBox {
                    color: #000;
                    font-family: 'DM Sans', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    text-transform: capitalize;
                }

                .iconExportBox {
                    width: 30px;
                    height: 30px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}