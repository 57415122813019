.uploadItemBox {
    width: 100%;
    height: 100%;

    .labelItem {
        color: rgba(0, 0, 0, 0.59);
        font-family: 'Montserrat', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: capitalize;
    }

    .afterUploadBox {
        width: 100%;
        height: 120px;
        border-radius: 10px;
        background: #EDF1F7;

        margin-top: 10px;
        margin-bottom: 20px;

        position: relative;

        cursor: pointer;

        .imagePreviewSrc {
            width: 100%;
            height: 120px;
            border-radius: 10px;
            // margin-top: 10px;
            object-fit: cover;
        }

        .uploadAddButton {
            width: 30px;
            height: 30px;

            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }

        .uploadDeleteButton {
            width: 30px;
            height: 30px;

            position: absolute;
            top: -10px;
            right: -10px;
            rotate: 45deg;
        }
    }

    .uploadBox {
        width: 100%;
        height: 100px;
        border-radius: 10px;
        background: #EDF1F7;

        padding-top: 20px;
        margin-top: 10px;
        margin-bottom: 20px;

        position: relative;
        text-align: center;

        cursor: pointer;

        color: rgba(107, 107, 107, 0.31);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;

        img {
            width: 30px;
            height: 30px;

            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.displayNone {
    display: none;
}