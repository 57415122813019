.loginBox {
    width: 90%;
    padding: 5% 5% 0;
    position: relative;
    // height: 100%;

    .headingOfBox {
        padding: 0 5%;

        h1 {
            font-family: 'DM Sans', sans-serif;
            font-size: 30px;
            font-weight: 700;
            line-height: 46px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #2B3674;
            margin-bottom: 0;
        }

        p {
            font-family: 'DM Sans', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #A3AED0;
            margin-top: 0;
        }
    }

    .inputBoxes {
        margin-top: 10%;
        padding: 0 5%;

        .inputItem {
            width: 100%;
            margin-top: 5%;

            .labelOfInput {
                width: 100%;
                font-family: 'DM Sans', sans-serif;
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                color: #2B3674;
                margin-bottom: 2%;
            }

            input {
                width: calc(100% - 20px);
                font-family: 'DM Sans', sans-serif;
                font-size: 13px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: -0.02em;
                text-align: left;

                border: 1px solid #E0E5F2;
                border-radius: 10px;
                padding: 15px 10px;
                transition: all 0.7s ease;
            }

            input::placeholder {
                color: #A3AED0;
                opacity: 1;
                /* Firefox */
            }

            input::-ms-input-placeholder {
                /* Edge 12 -18 */
                color: #A3AED0;
            }
        }

        .otpInput {
            width: 100%;
            margin-top: 10%;
            margin-bottom: 15%;

            .mainLabelOfInput {
                width: 100%;
                font-family: 'DM Sans', sans-serif;
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                color: #4318FF;
                margin-bottom: 10%;
            }

            .labelOfInput {
                width: 100%;
                font-family: 'DM Sans', sans-serif;
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                color: #2B3674;
                margin-bottom: 2%;
            }

            .miniLabelOfInput {
                width: 100%;
                font-family: 'DM Sans', sans-serif;
                font-size: 11px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                color: #4318FF;
                margin-top: 5%;
            }

            .groupInput {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 10px;

                input {
                    width: calc(100% - 20px);
                    font-family: 'DM Sans', sans-serif;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: -0.02em;
                    text-align: center;

                    border: 1px solid #E0E5F2;
                    border-radius: 10px;
                    padding: 15px 10px;
                    transition: all 0.7s ease;
                }

                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input[type=number] {
                    appearance: textfield;
                    -moz-appearance: textfield;
                }
            }
        }

        .error {
            input {
                border: 1px solid #FF1A1A;
                background: rgba(255, 50, 50, 0.08);
            }
        }
    }

    .someExtraThings {
        width: 90%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: 5%;
        padding: 0 5%;

        .leftSide {
            font-family: 'DM Sans', sans-serif;
            font-size: 11px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #2B3674;
            display: flex;
            align-items: center;

            input {
                color: #2B3674;
            }
        }

        .rightSide {
            font-family: 'DM Sans', sans-serif;
            font-size: 11px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #4318FF;
            cursor: pointer;
        }

    }

    .loginButton {
        text-align: center;
        width: 100%;

        font-family: 'DM Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: #FFFFFF;

        background: #4318FF;
        border-radius: 10px;
        margin-top: 10%;
        padding: 20px 0;

        cursor: pointer;

    }

    .contactUsText {
        font-family: 'DM Sans', sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #707EAE;

        margin-top: 5%;

        span {
            font-weight: 800;
            color: #4318FF;
        }
    }

    .showErrorNow {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 60%;
        padding-top: 10px;
        padding-bottom: 10px;

        border-radius: 0px 0px 30px 30px;
        background: linear-gradient(176deg, rgba(255, 24, 24, 0.50) -134.32%, rgba(255, 255, 255, 0.00) 146.27%);

        color: #FF1818;
        font-family: 'DM Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 83.333% */
        letter-spacing: -0.48px;
        text-transform: capitalize;
        text-align: center;

        animation: errorPopup 0.7s ease;
    }

}

@keyframes errorPopup {
    0% {
        top: -50px;
    }

    100% {
        top: 0px;
    }
}

.loadingScreen {
    width: 100%;
    height: 100%;
    position: relative;

    .waitingText {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);

        font-family: 'DM Sans', sans-serif;
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #2B3674;
    }

    .progress {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        height: 10px;
        width: 50%;
        border: 10px solid #FFC42E;
        border-radius: 15px;
    }

    .progress .color {
        position: absolute;
        background-color: #4318FF;
        width: 0px;
        height: 10px;
        border-radius: 15px;
        animation: progres 2s infinite linear;
    }
}

@keyframes progres {
    0% {
        width: 0%;
    }

    25% {
        width: 50%;
    }

    50% {
        width: 75%;
    }

    75% {
        width: 85%;
    }

    100% {
        width: 100%;
    }
}