.propertyTotalCount {
    width: 100%;
    position: relative;

    .listOfCards {
        width: 100%;

        display: flex;
        justify-content: flex-start;
        gap: 5%;

        .listingCard {
            position: relative;
            width: 400px;
            height: 100%;

            .backgroundImage {
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }

            .mainContentCardList {
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 20px);
                height: calc(100% - 20px);

                display: flex;
                flex-direction: column;

                justify-content: flex-end;

                padding: 10px 10px;

                .countNumber {
                    color: #FFF;
                    font-family: 'DM Sans', sans-serif;
                    font-size: 70px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    text-transform: uppercase;
                }

                .aboutCount {
                    width: 250px;
                    color: #FFF;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%;
                    text-transform: uppercase;
                }
            }
        }

        .addListCard {
            position: relative;
            width: 350px;
            height: 100%;
            cursor: pointer;

            .backgroundImage {
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }

            .centerCardInfo {
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 20px);
                height: calc(100% - 20px);

                display: flex;
                flex-direction: row;
                gap: 20px;

                justify-content: space-around;
                align-items: center;

                padding: 10px 10px;

                .aboutCard {
                    color: #000;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    text-transform: capitalize;
                }

                .leonOfCard {
                    width: 100px;
                    height: 100px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}