.inputSimpleBox {
    width: 100%;
    height: 100%;

    .labelOfInputBox {
        color: rgba(0, 0, 0, 0.59);
        font-family: 'Montserrat', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: capitalize;
    }

    input {
        width: calc(100% - 30px);
        border-radius: 10px;
        background: #EDF1F7;

        padding: 15px 15px;
        margin-top: 10px;

        border: none;
        outline: none;

        color: rgba(0, 0, 0, 1);
        font-family: 'DM Sans', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        // text-transform: capitalize;
    }

    .custom-date-input {
        width: calc(100% - 30px);
        border-radius: 10px;
        background: #EDF1F7;

        padding: 15px 15px;
        margin-top: 10px;

        border: none;
        outline: none;

        color: rgba(0, 0, 0, 1);
        font-family: 'DM Sans', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: uppercase !important;
    }

    .editorBoxForCK {
        padding: 10px 20px;
        background: #EDF1F7;
        border-radius: 10px;
    }

    .ck.ck-editor {
        width: 100%;
        border-radius: 10px;
        background: #EDF1F7;

        border: none;
        outline: none;

        color: rgba(0, 0, 0, 1);
        font-family: 'DM Sans', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        // text-transform: capitalize;
    }

    input::placeholder {
        color: rgba(107, 107, 107, 0.31);
        opacity: 1;
        /* Firefox */
    }

    input::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: rgba(107, 107, 107, 0.31);
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .mainDropDownBoxInputBox {
        width: calc(100% - 30px);
        border-radius: 10px;
        background: #EDF1F7;

        padding: 15px 15px;
        margin-top: 10px;

        border: none;
        outline: none;

        color: rgba(0, 0, 0, 1);
        font-family: 'DM Sans', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;
        cursor: pointer;
        user-select: none;

        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            img {
                width: 15px;
                height: 15px;
            }
        }
    }

    .dropDownBoxInputBox {
        position: absolute;
        top: 100%;
        left: 0;

        width: 100%;
        height: min-content;

        display: grid;
        margin-top: -10px;

        

        .itemDropDownBoxInputBox {
            background: #EDF1F7;

            padding: 15px 15px;
    
            border: none;
            outline: none;
    
            color: rgb(41, 41, 41);
            font-family: 'DM Sans', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-transform: capitalize;
            cursor: pointer;
            user-select: none;

            &:last-child {

                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &:hover {
                background: #dceaff;
            }
        }

    }
}