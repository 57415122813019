.dataTableStyles {
    width: 100%;
    margin-top: -2vh;
    margin-bottom: -2vh;
    padding-bottom: 5vh;
    border-bottom: 1px solid rgba(188, 188, 188, 0.31);



    .entryOfTableProperty {
        width: 100%;

        display: grid;
        grid-template-columns: 2fr 2fr 2fr 3fr;
        gap: 40px;
        align-items: center;

        padding-top: 10px;
        padding-bottom: 10px;

        .imageEntry {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;
            align-items: center;
            margin-top: 3vh;

            span {
                color: #000;
                font-family: 'DM Sans', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: capitalize;
            }

            img {
                width: 100%;
                height: 125px;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        .nameEntry {
            color: #000;
            font-family: 'DM Sans', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            text-transform: capitalize;
        }

        .locationEntry {
            color: #000;
            font-family: 'DM Sans', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            text-transform: capitalize;
        }

        .modifyingOption {
            width: 100%;

            .allButtons {
                width: 100%;

                display: flex;
                justify-content: flex-end;
                gap: 20px;

                .button {
                    width: max-content;
                    display: flex;
                    gap: 10px;
                    padding: 10px 15px;

                    color: #FFF;
                    font-family: 'DM Sans', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;

                    border-radius: 10px;

                    cursor: pointer;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                .viewButton {
                    background: #2E8AFF;
                }

                .editButton {
                    background: #FFC42E;
                }

                .deleteButton {
                    background: #A92525;
                }
            }
        }
    }
}

.addProperty {
    width: 100%;
    padding: 5vh 0;

    .addPropertyPanel {
        width: calc(100% - 60px);
        padding: 40px 30px;

        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.10);

        .headingTitle {
            color: #000;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
            margin-top: 10vh;
            margin-bottom: 2vh;

            display: flex;
            align-items: center;
            gap: 40px;

            &:nth-child(1) {
                margin-top: 0;
            }

            .backButton {
                width: max-content;

                padding: 10px 20px;

                display: flex;
                gap: 20px;

                color: #315DAF;
                font-family: 'DM Sans', sans-serif;
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;

                border-radius: 10px;
                background: #EDF1F7;

                cursor: pointer;

                img {
                    width: 20px;

                }
            }
        }

        .imageUploadSection {
            width: 100%;
            margin: 5vh 0;

            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            gap: 20px;
        }

        .fullTextBox {
            width: 100%;
            margin: 5vh 0;
        }

        .inputDropdown {
            width: 100%;
            display: flex;
            flex-direction: column;

            .dropdown {
                width: 100%;
                position: relative;

                .dropbtn {
                    border-radius: 10px;
                    background: #EDF1F7;
                    color: #000;
                    padding: 16px;
                    border: none;

                    font-family: "DM Sans", sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    text-transform: capitalize;
                }

                .dropdown-content {
                    width: 100%;
                    display: block;
                    position: absolute;
                    background-color: #f6f6f6;
                    min-width: 160px;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    z-index: 1;

                    .dropdownItem {
                        cursor: pointer;
                        color: black;
                        padding: 12px 16px;
                        text-decoration: none;
                        display: block;
                    }
                }
            }
        }

        .halfTextBox {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }

        .error {
            input {
                border: 1px solid #FF1A1A;
                background: rgba(255, 50, 50, 0.08);
            }

            .uploadBox {
                border: 1px solid #FF1A1A;
                background: rgba(255, 50, 50, 0.08);
            }
        }

        .errorBar {
            color: #FF1A1A;
            font-family: 'DM Sans', sans-serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            margin-top: 5px;
        }

        .accessSection {
            width: calc(100% - 30px);

            padding: 10px 15px;

            border-radius: 10px;

            .innerAccessSection {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 40px;
                margin-bottom: 30px;

                .accessSectionItem {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    cursor: pointer;

                    .selectedOrNotBox {
                        width: 20px;
                        height: 20px;
                        border-radius: 3px;
                        border: 1px solid #000;
                    }

                    .labelForSelectedOrNotBox {
                        color: #000;
                        text-align: center;
                        font-family: 'DM Sans', sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                    }
                }

                .activeAccessSectionItem {
                    .selectedOrNotBox {
                        border: 1px solid #184C8E;
                        background: #184C8E;
                    }

                    .labelForSelectedOrNotBox {
                        color: #184C8E;
                    }
                }
            }
        }

        .offeringSection {
            width: calc(100% - 30px);

            padding: 10px 15px;

            border-radius: 10px;
            background: #EDF1F7;

            .innerOfferingSection {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                gap: 40px;

                .offeringSectionsBox {
                    width: 100%;
                    position: relative;

                    .offeringSectionHeading {
                        color: rgba(0, 0, 0, 0.87);
                        font-family: 'DM Sans', sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        text-transform: capitalize;
                        margin: 20px 0 30px;
                    }

                    .offeringSectionAllItemsBox {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        margin-bottom: 30px;

                        .offeringSectionItem {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            cursor: pointer;

                            .selectedOrNotBox {
                                width: 10px;
                                height: 10px;
                                border-radius: 3px;
                                border: 1px solid #000;
                            }

                            .labelForSelectedOrNotBox {
                                color: #000;
                                text-align: center;
                                font-family: 'DM Sans', sans-serif;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: capitalize;
                            }
                        }

                        .activeOfferingSectionItem {
                            .selectedOrNotBox {
                                border: 1px solid #184C8E;
                                background: #184C8E;
                            }

                            .labelForSelectedOrNotBox {
                                color: #184C8E;
                            }
                        }
                    }

                    .separator {
                        width: 2px;
                        height: 100%;

                        position: absolute;
                        top: 0;
                        left: -20px;

                        opacity: 0.5;
                        background: #FFF;
                    }
                }
            }
        }

        .amenitiesSection {
            width: calc(100% - 30px);

            padding: 10px 15px;

            border-radius: 10px;
            background: #EDF1F7;

            .offeringSectionHeading {
                color: rgba(0, 0, 0, 0.87);
                font-family: 'DM Sans', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: capitalize;
                margin: 20px 0 30px;
            }

            .innerAmenitiesSection {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                gap: 20px;
                margin: 20px 0 30px;

                .amenitiesSectionItem {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    cursor: pointer;

                    .selectedOrNotBox {
                        width: 10px;
                        height: 10px;
                        border-radius: 3px;
                        border: 1px solid #000;
                    }

                    .imgForSelectedOrNotBox {
                        width: 10px;
                        height: 100%;

                        img {
                            width: 100%;
                        }
                    }

                    .labelForSelectedOrNotBox {
                        color: #000;
                        text-align: center;
                        font-family: 'DM Sans', sans-serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                    }
                }

                .activeAmenitiesSectionItem {
                    .selectedOrNotBox {
                        border: 1px solid #184C8E;
                        background: #184C8E;
                    }

                    .labelForSelectedOrNotBox {
                        color: #184C8E;
                    }
                }
            }
        }

        .submitButton {
            width: calc(100% - 30px);
            display: flex;
            justify-content: flex-end;
            padding: 40px 15px 0;

            .submitButtonBox {
                width: 30%;
                min-width: min-content;

                padding: 10px 20px;

                border-radius: 15px;
                background: #14C21B;

                display: flex;
                justify-content: center;
                gap: 20px;

                cursor: pointer;

                span {
                    color: #FFF;
                    font-family: 'DM Sans', sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: capitalize;
                }

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}