.loginModule {
    width: 100%;
    height: 100%;

    .upperNav {
        width: 100%;
        height: 80px;
        background: #FFFFFF;

        .innerSpaceUpperNav {
            padding: 20px 2%;
            height: calc(100% - 40px);
            display: flex;

            span {
                font-family: 'DM Sans', sans-serif;
                font-size: 13px;
                font-weight: 500;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
            }

            img {
                height: 100%;
            }
        }
    }

    .mainSection {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        .backgroundImage {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;


            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::after {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            }
        }

        .centerBoxOfMainSection {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 100;

            .innerDisplayArea {
                padding: 5% 5%;
                width: 90%;
                height: 80%;

                display: grid;
                grid-template-columns: auto 32.5%;
                gap: 15%;

                .leftSideBranding {
                    .logoShow {
                        width: 40%;

                        img {
                            width: 100%;
                        }
                    }

                    .headingOfSection {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 42px;
                        font-weight: 600;
                        line-height: 50px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #FFFFFF;

                        margin-top: 5%;
                    }

                    .subTextOfSection {
                        font-family: DM Sans;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: -0.02em;
                        text-align: left;
                        color: #ffffffbe;
                        margin-top: 2%;
                    }
                }

                .rightSideForm {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background: #FFF;

                    overflow: auto;

                    .loginBox {
                        position: relative;
                        z-index: 10;
                    }

                    .smallNotificationForm {
                        position: absolute;
                        top: 0;
                        left: 0;

                        width: 100%;
                        height: 100%;

                        background: #00000090;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        z-index: 100;

                        animation: slowCome 1s ease;

                        .mainBoxNotificationBox {
                            width: calc(80% - 30px);
                            height: max-content;

                            padding: 20px 15px;

                            border-radius: 4px;
                            border: 2px solid #B6CAAE;
                            background: #D6FFC6;

                            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.10);

                            display: grid;
                            grid-template-columns: 25px 1fr;
                            gap: 20px;

                            .iconOfNotificationBox {
                                width: 100%;

                                img {
                                    width: 100%;
                                }
                            }

                            .textBoxOfNotificationBox {
                                width: 100%;

                                .headingOfNotification {
                                    color: #424242;
                                    font-family: 'Montserrat', sans-serif;
                                    font-size: 16px;
                                    font-weight: 500;
                                    line-height: 18px;
                                }

                                .subTextOfNotification {
                                    color: #757575;

                                    font-family: 'DM Sans', sans-serif;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    /* 150% */
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes slowCome {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 800px) {
    .loginModule {
        .mainSection {
            .centerBoxOfMainSection {
                .innerDisplayArea {
                    grid-template-columns: auto;

                    .leftSideBranding {
                        display: none;
                    }
                }
            }
        }
    }
}